import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const Accordion = ({data, colors}) => {
  const [currentImg, setImgIndex] = useState(0);
  const [ifClicked, setClicked] = useState(true);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    touchMove: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 10000,
    afterChange(index) {
      setImgIndex(index);
    },
  };
  useEffect(() => {
    let timeout;
    timeout = setTimeout(() => {
      if (ifClicked) {
        setImgIndex((prev) => {
          if (prev === data.length - 1) {
            return 0;
          }
          return prev + 1;
        });
      }
    }, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [currentImg, data.length, ifClicked]);
  return (
    <div className='accordionContainer'>
      <div className='catImg'>
        {data.map((card, index) => (
          <img
            className={
              index === currentImg ? "cardimg cardimgactive" : "cardimg hide"
            }
            src={card.img}
            alt=''
          />
        ))}
      </div>

      <div className='cardwrap'>
        <br />
        {data.map((card, index) => (
          <div
            className={`catCard ${currentImg === index ? "cactive" : ""}`}
            style={{
              background: colors[2],
              borderColor: currentImg === index ? colors[0] : "transparent",
            }}
          >
            <div className='loaderLine' style={{background: colors[0]}} />
            <h5
              className='medium'
              onClick={() => {
                setImgIndex(index);
                setClicked(false);
              }}
            >
              {card.title}
            </h5>
            <br />
            {currentImg === index && <p>{card.desc}</p>}
          </div>
        ))}
      </div>

      <div className='cardwrap mobile'>
        <Slider {...settings}>
          {data.map((card) => (
            <div className='catCard'>
              <h5 className='medium'>{card.title}</h5>
              <br />
              <p>{card.desc}</p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Accordion;

import {graphql} from "gatsby";
import React, {useState} from "react";
import {Link} from "react-scroll";

import video3 from "../assets/AutoAdaptsVid.mp4";
import video2 from "../assets/AutoColor.mp4";
import ColorIcon from "../assets/AutoTheming.svg";
import MimicIcon from "../assets/DataSync.svg";
import PreviewIcon from "../assets/EyeIcon.svg";
import video1 from "../assets/QuickMimic.mp4";
import AdaptIcon from "../assets/Resize.svg";
import video4 from "../assets/SmartPreview.mp4";
import Accordion from "../components/Accordion";
import Layout from "../components/Layout";
import StructuredData, {StructuredDataType} from "../components/StructuredData";
import Subscribe from "../components/pages/home/Subscribe/Subscribe";
import "./features.css";

const featStuff = [
  {
    id: "you",
    title: "For you",
    shortImg:
      "https://media.kubric.io/api/assetlib/8215d835-c2ca-4c55-a1ac-b360a8b9033f.png",
    subTitle: "Speed up small tasks to make time for big ideas",
    color: ["#E99900", "#FDF5D5", "rgba(243, 183, 67, 0.16)"],
    cards: [
      {
        img: "https://media.kubric.io/api/assetlib/ca21b1e8-daed-411c-ba5f-1369adac632b.png",
        title: "Design bots to speed your game",
        desc: "Speed up your workflow with design bots that help free your time. Need backgrounds blurred for one, but removed for another? Auto-adapts of the mobile & web banners? You name it, you got it",
      },
      {
        img: "https://media.kubric.io/api/assetlib/2204549d-9b75-44bf-996d-64dba8bfa632.png",
        title: "Edit in one place, upgrade everywhere",
        desc: 'Update any element you need to and apply that change to everything else. An update in copy, logos, backgrounds, offers can all now updated in one click. "Visual" find & replace - not a myth!',
      },
      {
        img: "https://media.kubric.io/api/assetlib/6507ee6b-b47f-47ec-835e-fd1aab85b071.gif",
        title: "Smart previews and publishing",
        desc: "Previews that automatically show you how your content will appear to your shoppers, along with quick-share to the channel of your choice means you don't have to sweat out it anymore.",
      },
      {
        img: "https://media.kubric.io/api/assetlib/46e65425-98f9-4652-9f53-add2d2efae9b.png",
        title:
          "Effortlessly publish to your social channels or e-commerce store",
        desc: "From Shopify to Custom Product Feeds, easily connect your catalog and store to trigger engaging content for all those add to cart reminders, price drop pings, sale stickers and more.",
      },
    ],
  },
  {
    id: "team",
    title: "For your team",
    shortImg:
      "https://media.kubric.io/api/assetlib/f6a0347e-c3fd-49d2-ba3a-47b004701a10.png",
    subTitle: "Collaborate effortlessly to make space for good content",
    color: ["#E7544B", "#FFE4DE", "rgba(254, 105, 96, 0.12)"],
    cards: [
      {
        img: "https://media.kubric.io/api/assetlib/abbbb1ad-abb5-47aa-b94f-12ce0fa4afce.png",
        title: "Easy editing for everyone",
        desc: 'With easy form based editors that you can share with business, marketing and other non-designers, you can now kill last all minute edits away. No more "Can you please change the copy and send me everything in 10 mins?"',
      },
      {
        img: "https://media.kubric.io/api/assetlib/f9c8d8c8-b347-4d85-be0d-2ee919429889.png",
        title: "Instantly setup your brand",
        desc: "Setup your brand guide into your workspace - color palettes, font libraries, asset whitelists, creative guardrails and business rules. This ensures that in the middle of the crazy Black Friday sale with 10 new team members across 5 timezones, your brand stays safe.",
      },
      {
        img: "https://media.kubric.io/api/assetlib/a9a6529f-5ea2-4c08-9dd7-aa3540925f05.png",
        title: "Upload or sync from anywhere, find in one place",
        desc: "Connect your designs, assets, product catalog, CRM data, licensed stock sources and more to your team workspace. No more frantic calls and pings ruining your teammate's vacay just cause you need to find that folder or those files.",
      },
      {
        img: "https://media.kubric.io/api/assetlib/35d0d150-64fe-4b3a-9943-9f37d2cebdd2.png",
        title: "All conversations, connected",
        desc: "All conversations around every single creative neatly tied together. So whether your marketing manager is responding on slack, or design head is approving over email, you don't have to remember the who the when or the where",
      },
      {
        img: "https://media.kubric.io/api/assetlib/2c653bb5-1ae1-49bd-9778-ed752324e5c1.png",
        title: "Work with clients & teammates in one place",
        desc: "Keep your clients, your vendors, your partners and - of course - all your teammates in loop. Work tends to move faster when everyone is on same page, isn't it? Especially with remote, this helps you avoid all unnecessary status update calls. Win-Win!",
      },
    ],
  },
  {
    id: "company",
    title: "For your company",
    shortImg:
      "https://media.kubric.io/api/assetlib/39d2f310-b532-4f92-a57c-028759d2ece6.png",
    subTitle: "Good content is good business",
    color: ["#8A3063", "#E8D6E1", "rgba(138, 48, 99, 0.08)"],
    cards: [
      {
        img: "https://media.kubric.io/api/assetlib/aa4a4afc-d858-4dfe-ba81-78df0ff28a14.png",
        title: "Triggered bulk actions",
        desc: "As a company, you want to run your business as dynamically as possible. That last minute offer updates or staggered sale go live or new collection launch is now so much easier with triggered bulk updates that not just auto-update the right content but also deliver it to your CDNs.",
      },
      {
        img: "https://media.kubric.io/api/assetlib/fd8c9dd0-08b1-4c91-a285-cfe5db7948b0.png",
        title: "Automated workflows",
        desc: "Automate routine checks and communication, so that you can focus back on connecting your brand to your users. Inputs missing from the copywriting team? On-hold emails remind them to revert on time. Image resolution looks iffy? Photographer gets auto-notified to share the right(sized) file. ",
      },
      {
        img: "https://media.kubric.io/api/assetlib/300b30c5-4c58-45a0-bdcf-c5589e081214.png",
        title: "Effortless scaling for those high sale days",
        desc: "Mason supports design operations at a global scale, with on-demand usage to mirror the way your company actually works. It flows seamlessly from a single user to your entire team. We have the right transparent usage packs for all your needs.",
      },
      {
        img: "https://media.kubric.io/api/assetlib/ac555e21-742b-4cb7-9121-075a1b2e95d3.png",
        title: "Security & firewalls",
        desc: "You get complete control and visibility into all your access information and data transfers. Best in global security and encryption. Firewall access to all kinds of information - assets, chats, creatives and even modules. Firewall access with user roles that you can customize.",
      },
    ],
  },
];

const Features = ({data}) => {
  const seoData = data.allSanitySeoMeta.nodes[0];
  const cmsRef = data.allSanityHomepage.nodes[0];
  const features = {
    one: {
      label: cmsRef.featsection[0].label,
      content: cmsRef.featsection[0].content,
      subtext: cmsRef.featsection[0].subtext,
      video: video1,
      icon: <MimicIcon />,
    },
    two: {
      label: cmsRef.featsection[1].label,
      content: cmsRef.featsection[1].content,
      subtext: cmsRef.featsection[1].subtext,
      video: video2,
      icon: <ColorIcon />,
    },
    three: {
      label: cmsRef.featsection[2].label,
      content: cmsRef.featsection[2].content,
      subtext: cmsRef.featsection[2].subtext,
      video: video3,
      icon: <AdaptIcon />,
    },
    four: {
      label: cmsRef.featsection[3].label,
      content: cmsRef.featsection[3].content,
      subtext: cmsRef.featsection[3].subtext,
      video: video4,
      icon: <PreviewIcon />,
    },
  };
  const [selectedFeature, setFeature] = useState(features.one);
  return (
    <Layout seoData={seoData}>
      <StructuredData
        type={StructuredDataType.PRODUCT}
        data={{
          key: "features",
        }}
      />
      <section className='top mt-12'>
        <video
          className='featVid'
          autoPlay
          muted
          loop
          playsInline
          src={video1}
        />
        <div className='topcontent'>
          <h1>The content toolbox for e-Commerce</h1>
          <h3 className='light'>
            Mason connects the dots from Design to Share.
          </h3>
        </div>
      </section>
      <section className='middle full-bleed layouted'>
        <div className='smolWrap'>
          {featStuff.map((cat) => (
            <Link to={cat.id} spy smooth duration={500} className='smolcard'>
              <img src={cat.shortImg} alt={cat.title} />
              <div className='up'>
                <h4>{cat.title}</h4>
                <p className='low'>{cat.subTitle}</p>
              </div>
            </Link>
          ))}
        </div>
      </section>
      <section id='feature' className='features full-bleed layouted'>
        <br />
        <br />
        <br />
        <h2 className='light'>Packed with power modes</h2>
        <div className='featShow'>
          <div className='vidBox'>
            <video
              autoPlay
              muted
              loop
              playsInline
              src={selectedFeature.video}
            />
          </div>
          <div className='descBox'>
            <h2 style={{margin: "0"}}>{selectedFeature.label}</h2>
            <p>
              <i>{selectedFeature.subtext}</i>
            </p>
            <p>{selectedFeature.content}</p>
          </div>
        </div>
        <div className='btnArea'>
          <div className='iconWrap'>
            <div
              className={`icon mimicIcon ${
                selectedFeature.label === "Mimic" ? "active" : ""
              }`}
              onClick={() => {
                setFeature(features.one);
              }}
            >
              <MimicIcon />
            </div>
            <div className='caption'>{cmsRef.featsection[0].label}</div>
          </div>
          <div className='iconWrap'>
            <div
              className={`icon colorIcon ${
                selectedFeature.label === "Auto Color" ? "active" : ""
              }`}
              onClick={() => {
                setFeature(features.two);
              }}
            >
              <ColorIcon />
            </div>
            <div className='caption'>{cmsRef.featsection[1].label}</div>
          </div>
          <div className='iconWrap'>
            <div
              className={`icon adaptIcon ${
                selectedFeature.label === "Auto Adapts" ? "active" : ""
              }`}
              onClick={() => {
                setFeature(features.three);
              }}
            >
              <AdaptIcon />
            </div>
            <div className='caption'>{cmsRef.featsection[2].label}</div>
          </div>
          <div className='iconWrap'>
            <div
              className={`icon previewIcon ${
                selectedFeature.label === "Smart Previews" ? "active" : ""
              }`}
              onClick={() => {
                setFeature(features.four);
              }}
            >
              <PreviewIcon />
            </div>
            <div className='caption'>{cmsRef.featsection[3].label}</div>
          </div>
        </div>
      </section>
      {featStuff.map((cat) => (
        <section
          id={cat.id}
          className='category full-bleed layouted'
          style={{background: cat.color[1]}}
        >
          <div className='featCategory'>
            <br />
            <br />
            <br />
            <h2>
              <span>{cat.title.split(" ")[0]}</span>
              &nbsp;
              <span style={{color: cat.color[0]}}>
                {cat.title.split(" ")[1]}
              </span>
              &nbsp;
              <span style={{color: cat.color[0]}}>
                {cat.title.split(" ")[2]}
              </span>
            </h2>
            <br />
            <h3 className='light catSubtitle'>{cat.subTitle}</h3>
            <br />
            <br />
          </div>
          <Accordion data={cat.cards} colors={cat.color} />
          <br />
          <br />
          <Subscribe buttonText='Get Started Now!' id='get-mason-app' />
        </section>
      ))}
    </Layout>
  );
};

export default Features;

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "features"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    allSanityHomepage {
      nodes {
        featsection {
          content
          label
          subtext
        }
      }
    }
  }
`;
